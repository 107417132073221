/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const CookiePolicy = () => {
  return (
    <Layout>
      <SEO title="Cookie Policy" />
      <div className="cms-container">
        <h1 className="cms-container__title">COOKIE POLICY</h1>
        <div className="cms-container__wrapper">
          <h3>Information about our use of cookies</h3>
          <p className="p2">
            <span className="s1">
              Our Site uses cookies to help us provide you with a good experience when you browse our Site and also
              allows us to improve our Site. By continuing to browse the site, you are agreeing to our use of cookies.
            </span>
          </p>
          <p className="p2">
            <span className="s1">
              A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
              computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
            </span>
          </p>
          <p className="p2">
            <span className="s1">We use the following cookies: </span>
          </p>
          <ul>
            <li className="li3">
              <span className="s1">
                <strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of
                our website. They include, for example, cookies that enable you to log into secure areas of our website,
                use a shopping cart or make use of e-billing services.{' '}
              </span>
            </li>
            <li className="li3">
              <span className="s1">
                <strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of
                visitors and to see how visitors move around our website when they are using it. This helps us to
                improve the way our website works, for example, by ensuring that users are finding what they are looking
                for easily.{' '}
              </span>
            </li>
            <li className="li3">
              <span className="s1">
                <strong>Functionality cookies.</strong> These are used to recognise you when you return to our website.
                This enables us to personalise our content for you, greet you by name and remember your preferences (for
                example, your choice of language or region).
              </span>
            </li>
            <li className="li3">
              <span className="s1">
                <strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have
                visited and the links you have followed. We will use this information to make our website and the
                advertising displayed on it more relevant to your interests. We may also share this information with
                third parties for this purpose.
              </span>
            </li>
          </ul>
          <p className="p2">
            <span className="s1">
              You can find more information about the individual cookies we use and the purposes for which we use them
              in the table below:
            </span>
          </p>
          <div className="table-responsive">
            <table>
              <tr>
                <th>Cookie Name</th>
                <th>Publisher</th>
                <th>Purpose</th>
                <th>More Information</th>
              </tr>
              <tr>
                <td className="cookie-name">__smSessionId</td>
                <td>Sumo</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://help.sumo.com/hc/en-us/articles/115004671147-How-Sumo-Cookies-Work#link2">
                    How-Sumo-Cookies-Work
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">__smTID</td>
                <td>Sumo</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://help.sumo.com/hc/en-us/articles/115004671147-How-Sumo-Cookies-Work#link2">
                    How Sumo Cookies Work
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">__smToken</td>
                <td>Sumo</td>
                <td>Marketing</td>
                <td>
                  <a href="https://help.sumo.com/hc/en-us/articles/115004671147-How-Sumo-Cookies-Work#link2">
                    How Sumo Cookies Work
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">__smVID</td>
                <td>Sumo</td>
                <td>Marketing</td>
                <td>
                  <a href="https://help.sumo.com/hc/en-us/articles/115004671147-How-Sumo-Cookies-Work#link2">
                    How Sumo Cookies Work
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">__stripe_mid</td>
                <td>Stripe</td>
                <td>Strictly Necessary/Functionality</td>
                <td>
                  <a href="https://stripe.com/cookies-policy/legal">Cookies and Similar Technologies</a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">__stripe_sid</td>
                <td>Stripe</td>
                <td>Strictly Necessary/Functionality</td>
                <td>
                  <a href="https://stripe.com/cookies-policy/legal">Cookies and Similar Technologies</a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">_ga</td>
                <td>Google Analytics</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                    Google Analytics Cookie Usage on Websites
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">_gat_UA-71764562-4</td>
                <td>Google Analytics</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                    Google Analytics Cookie Usage on Websites
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">_gid</td>
                <td>Google Analytics</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                    Google Analytics Cookie Usage on Websites
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">_hjIncludedInSample</td>
                <td>HotJar</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://www.hotjar.com/cookies">Cookie Information</a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">_mealpal_preferences</td>
                <td>MealPal</td>
                <td>Strictly necessary/Functionality</td>
                <td>
                  Stores application preferences, including filter selections and a timestamp when the user first saw a
                  feature.
                </td>
              </tr>
              <tr>
                <td className="cookie-name">_mealpal_session</td>
                <td>MealPal</td>
                <td>Strictly necessary/Functionality</td>
                <td>Stores your user's session ID while logged in to MealPal's website</td>
              </tr>
              <tr>
                <td className="cookie-name">1P_JAR</td>
                <td>Google Analytics</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                    Google Analytics Cookie Usage on Websites
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">JSESSIONID</td>
                <td>New Relic</td>
                <td>Analytical/Performance</td>
                <td>
                  <a href="https://docs.newrelic.com/docs/browser/new-relic-browser/page-load-timing-resources/new-relic-cookies">
                    New Relic cookies
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">cookies_accepted</td>
                <td>MealPal</td>
                <td>Strictly necessary/Functionality</td>
                <td>Stores a flag when the user has seen the cookie policy banner.</td>
              </tr>
              <tr>
                <td className="cookie-name">fr</td>
                <td>Facebook</td>
                <td>Marketing</td>
                <td>Facebook tracks opted-out Facebook users for advertising purposes.</td>
              </tr>
              <tr>
                <td className="cookie-name">guest_id</td>
                <td>Twitter</td>
                <td>Marketing</td>
                <td>Twitter uses information to help personalize the Twitter experience.</td>
              </tr>
              <tr>
                <td className="cookie-name">IDE</td>
                <td>Doubleclick</td>
                <td>Marketing</td>
                <td>
                  <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                    Google Analytics Cookie Usage on Websites
                  </a>
                </td>
              </tr>
              <tr>
                <td className="cookie-name">isLoggedIn</td>
                <td>MealPal</td>
                <td>Strictly necessary/Functionality</td>
                <td>Stores a flag when there is currently a user logged in</td>
              </tr>
              <tr>
                <td className="cookie-name">personalization_id</td>
                <td>Twitter</td>
                <td>Marketing</td>
                <td>Twitter uses information to help personalize the Twitter experience.</td>
              </tr>
            </table>
          </div>
          <p className="p5">
            <span className="s1">
              Please note that third parties (including, for example, advertising networks and providers of external
              services like web traffic analysis services) may also use cookies, over which we have no control. These
              cookies are likely to be analytical/performance cookies or targeting cookies{' '}
            </span>
          </p>
          <p className="p5">
            <span className="s1">
              You block cookies by activating the setting on your browser that allows you to refuse the setting of all
              or some cookies. However, if you use your browser settings to block all cookies (including essential
              cookies) you may not be able to access all or parts of our site.{' '}
            </span>
          </p>
          <p className="p5">
            <span className="s1">Except for essential cookies, all cookies will expire after 1 year.</span>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default CookiePolicy
